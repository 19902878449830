.badge {
  padding: 0.25em 0.45em;
  font-size: 0.75em;
  font-weight: 700;
  border-radius: 0.375rem;
  margin-left: 0.25em;
}

.bg-danger{
  background-color: red;
}
